import { Controller } from "stimulus"
import SlimSelect from 'slim-select'
import autoComplete from "@tarekraafat/autocomplete.js"
import '../../src/css/modules/slim_select.scss'
import '../../src/css/modules/sticky.sass'

export default class extends Controller {
  static targets = [
    'modal',
    'phoneField',
    'counter',
    'select',
    'autocom',
    'autocomResult',
    'autocomResults',
    'badge'
  ]

  initialize() {
    // close all if click outside
    document.body.addEventListener('click', evt => {
      if (!evt.target.closest('.js-sticky')) this.closeAll()
    })
  }

  // action
  openModal(event) {
    const this_modal = event.currentTarget.nextElementSibling
    this_modal.classList.toggle('hidden')
    this.modalTargets.forEach(el => {
      if (el !== this_modal) el.classList.add('hidden')
    })
    const classList = event.currentTarget.classList
    const closestHolder = event.currentTarget.closest('.js-sticky__item').querySelector(".js-holder")
    if (classList.contains('js-sms') && !this.select) {
      this.initSlimSelect()
    } else if (classList.contains('js-search')) {
      if (!this.autocom) this.initAutocom()
      this.autocomTarget.focus()
    } else if (classList.contains('js-last-sms')) {
      this.appendLastSms(closestHolder)
    } else if (classList.contains('js-last-calls')) {
      this.appendLastCalls(closestHolder)
    }
  }

  closeAll() {
    this.modalTargets.forEach(el => el.classList.add('hidden'))
  }

  // action
  closeModal() {
    this.closeAll()
  }

  initSlimSelect() {
    this.select = new SlimSelect({
      select: this.selectTarget,
      // do not focus on search fields for small devices
      searchFocus: window.innerWidth > 768,
      placeholder: 'Select person',
      onChange: info => {
        if (info.value !== '') {
          this.phoneFieldTarget.value = info.value
        }
      }
    })

    fetch(this.data.get('smspath'))
      .then(res => {
        return res.json()
      })
      .then(json => {
        this.select.setData(json.data)
      })
  }

  initAutocom() {
    this.autocom = new autoComplete({
      selector: () => this.autocomTarget,
      data: {
        src: async () => {
          const query = this.autocomTarget.value
          const source = await fetch(this.data.get('searchpath') + '?q=' + query)
          return await source.json()
        },
        keys: ['fullname', 'sp_number', 'id']
      },
      threshold: 3,
      debounce: 300,
      highlight: { render: true },
      resultItem: {
        element: (item, data) => {
          this.autocomResultsTarget.classList.add('hidden')

          item.innerHTML = `
                <div class="autocom__reswrap">
                    <a href="${data.value.property_path}" class="autocom__link" target="_blank">
                        <span class="autocom__address">${data.value.full_address}</span>
                    </a>
                    <span class="autocom__sp">(${data.value.sp_number})</span>
                    <button class="autocom__copy-btn" data-address="${data.value.full_address}">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" title="Copy address to clipboard" class="hover:text-blue-600">
                            <path d="M8 3a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"/>
                            <path d="M8.5 2.5v.634c0 .248.196.448.438.472.413.04.812.147 1.182.315a.5.5 0 0 1 .262.657l-.315.632a.5.5 0 0 1-.707.207.864.864 0 0 0-.374-.172.5.5 0 0 1-.416-.494v-.5a.5.5 0 0 0-1 0v.5a.5.5 0 0 1-.416.494 1.48 1.48 0 0 0-.373.172.5.5 0 0 1-.708-.206l-.315-.633a.5.5 0 0 1 .262-.657c.37-.168.769-.275 1.182-.315a.476.476 0 0 0 .438-.472V2.5a.5.5 0 0 1 .5-.5Z"/>
                            <path d="M4.085 1H3.5A1.5 1.5 0 0 0 2 2.5v12A1.5 1.5 0 0 0 3.5 16h9a1.5 1.5 0 0 0 1.5-1.5v-12A1.5 1.5 0 0 0 12.5 1h-.585c.055.156.085.325.085.5V2a2 2 0 0 1-2 2h-3a2 2 0 0 1-2-2v-.5c0-.175.03-.344.085-.5ZM8.5 13.5V12h1a.5.5 0 0 0 0-1h-1v-1a.5.5 0 0 0-1 0v1h-1a.5.5 0 0 0 0 1h1v1.5a.5.5 0 0 0 1 0Z"/>
                        </svg>
                    </button>
                </div>
            `;

          item.querySelector('.autocom__copy-btn').addEventListener('click', (event) => {
            const address = event.currentTarget.getAttribute('data-address');
            navigator.clipboard.writeText(address)
              .then(() => {
                alert('Address copied successfully!');
              })
              .catch(err => {
                console.error('Failed to copy address:', err);
                alert('Failed to copy address. Please try again.');
              });
          });
        },
        highlight: true
      },
      resultsList: {
        element: (list, data) => {
          const info = document.createElement("p");
          if (data.results.length > 0) {
            info.innerHTML = `Displaying <strong>${data.results.length}</strong> out of <strong>${data.matches.length}</strong> results`;
          } else {
            info.innerHTML = `Found <strong>${data.matches.length}</strong> matching results for <strong>"${data.query}"</strong>`;
          }
          list.prepend(info);
        },
        noResults: true,
        maxResults: 15,
        tabSelect: true
      },
      noResults: () => {
        this.autocomResultsTarget.classList.remove('hidden')
        this.autocomResultsTarget.innerText = 'No results'
      },
      onSelection: async feedback => {
        const response = await fetch(feedback.selection.value.path)
        this.autocomResultTarget.innerHTML = await response.text()
      }
    })
  }

  // action
  counterChange(event) {
    const text_max = 160
    const text_length = event.currentTarget.value.length
    const message_amount = text_length ? Math.ceil(text_length / text_max) : 1
    const text_remaining = text_length ? message_amount * text_max - text_length : text_max
    this.counterTarget.innerHTML = `${text_remaining} characters remaining - ${message_amount} message`
  }

  appendLastSms(holder) {
    // hide badge
    this.badgeTarget.classList.add('hidden')
    fetch(this.data.get('lastsmspath'))
      .then(res => {
        return res.text()
      })
      .then(text => {
        holder.innerHTML = text
      })
  }

  appendLastCalls(holder) {
    fetch(this.data.get('lastcallspath'))
      .then(res => {
        return res.text()
      })
      .then(text => {
        holder.innerHTML = text
      })
  }

}
